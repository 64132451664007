import React from "react"

import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Thank You!" />
    <Hero pageTitle="Thank You!" />

    <section className="section-1">
      <div className="container smaller">
        <div className="row text-center intro">
          <div className="col-md-8 mx-auto d-flex flex-column">
            <h2>
              Message{" "}
              <span className="featured">
                <span>Received</span>
              </span>
            </h2>
            <i class="fas fa-envelope-open-text fa-7x my-5"></i>
            <strong>Your submission has been received.</strong> Thank you for
            contacting R.A. Wiedemann &amp; Assocaites!
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
